import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom'
import { Container,Nav,Navbar,Modal,Row, Col,Button} from 'react-bootstrap'
import { MdOutlineLogin } from "react-icons/md";

import  '../appbar/appbar.css'
import LoginButton from '../LoginButton';
const AppBar = () => {
  const [show, setShow] = useState(false);

  const closeLogin = () => setShow(false);
  const openLogin = () => setShow(true);
  return (
    <div>
    <Navbar expand="lg" className="bg-white navfont py-0">
    <Container className='py-0'>
        <Navbar.Brand  as={Link} to={"/"} className='me-auto py-0'>
          <img src={require('../../images/logo.png')} alt='srivarugreen energy' title='srivarugreen energy'  className='img-fluid logo'/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto text-center">
          <Nav.Link as={Link} to={"/"}>Home</Nav.Link>
          <Nav.Link as={Link} to={"/about"}>About Us</Nav.Link>
          <Nav.Link href="/service"to={"/service"}>Service</Nav.Link>
          {/* <Nav.Link as={Link}to={"/"}>Gallery</Nav.Link> */}
          <Nav.Link as={Link} to={"/contact"}>Contact</Nav.Link>
          <Nav.Link as={Link} to={"/"}className='d-lg-none d-md-block' onClick={openLogin}>Login <span className='login'> <MdOutlineLogin /></span></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      <Nav.Link as={Link} to={"/"}className='d-none d-lg-block login' onClick={openLogin}>Login <span > <MdOutlineLogin /></span></Nav.Link>
      </Container>
    </Navbar>
    <>
    {/* <Modal show={show} onHide={closeLogin} centered>
    <Modal.Header closeButton>
          <Modal.Title>
            <div>Customer Login</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='justify-content-center'>
              <Col lg='8' className='py-3'>
                <div className='mx-auto'>
                  <form>
                    <label>
                      User ID
                    </label>
                    <input type="text" name="name" className='p-2 w-100' />
                  </form>
                </div>
              </Col>
              <Col lg='8' className='py-3'>
                <div className='mx-auto w-100'>
                  <form>
                    <label>Password</label>
                    <input type="text" name="name" className='p-2 w-100' />
                  </form>
                </div>
              </Col>
              <Col lg='12'>
                <div className='text-center'>
                  <LoginButton label={<>Login</>}onClick={closeLogin}/>
                  </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal> */}
      <Modal show={show} onHide={closeLogin} centered>
    <Modal.Header closeButton>
          <Modal.Title>
            <div>Login</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Container>
            <Row className="justify-content-center">
              <Col lg="8" className="py-3">
                <Button
                  href="https://crm.srivarugreenenergy.com/login"
                  className="w-100 login-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Customer Login
                </Button>
              </Col>
              <Col lg="8" className="py-3">
                <Button
                  href="https://admin.srivarugreenenergy.com/login"
                  className="w-100 login-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Admin Login
                </Button>
              </Col>
            </Row>
          </Container>
          
        </Modal.Body>
      </Modal>
    </>
    </div>
  )
}

export default AppBar