import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import '../footer/footer.css'
const Footer = () => {
    return (
        <div>
            <div className='footer-bg padding'>
                <Container>
                    <Row>
                        <Col lg='3' className='py-3'>
                            <div className='ft-logo py-3'>
                                <img src={require('../../images/logo.png')} alt='Srivaru green energy' title='Srivaru green energy' className='img-fluid' />
                            </div>
                            <div className='px-lg-4 regular fs-5'>
                                219/2 vairam nagar, <br />
                                Manickapuram road,<br />
                                Palladam,Tiruppur - 641664
                            </div>
                        </Col>
                        <Col lg='9' className='py-lg-3 align-self-center '>
                            <Row>
                                <Col lg='4' className='py-lg-3 footer-link'>
                                    <div className=' medium  pt-5 px-lg-5'>
                                        <div className='bold  pb-4 fs-4'> Information</div>
                                        <div className='fs-5'>
                                            <div>Industry Anlaytics</div>
                                            <div>News And Release</div>
                                            <div>Events</div>
                                            <div>About us</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='4' className='py-lg-3 footer-link'>
                                    <div className=' medium  pt-5 px-lg-5 '>
                                        <div className='bold  pb-4 fs-4'>Useful Links</div>
                                        <div className='fs-5'>
                                            <div>Services </div>
                                            <div>Partners</div>
                                            <div>Innovation</div>
                                            <div>Support</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='4' className='py-lg-3 footer-link'>
                                    <div className=' medium  pt-5 px-lg-5 '>
                                        <div className='bold  pb-4 fs-4'> Contact </div>
                                        <div className='fs-5'>
                                            <div>Headquarters</div>
                                            <div>Manager</div>
                                            <div>Sales</div>
                                            <div>Why Us</div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col lg='12' className='py-3'>
                            <div className='bottom-border'></div>
                        </Col>
                        <Col lg='4' md='12' xs='12' className='py-lg-2 py-1'>
                            <div className='bold text-lg-left text-center'>Copyright &#169; All Rights Received</div>
                        </Col>
                        <Col lg='4' md='12' xs='12' className='py-lg-2 py-1'>
                            <div className='text-center bold'> Developed by Barbikan Technologies</div>
                        </Col>
                        <Col lg='4' md='12' xs='12' className='py-lg-2 py-1'>
                            <div className='text-lg-end text-center regular fw-600'>
                                <span className='mx-lg-4 mx-2'>Terms </span>
                                <span className='mx-lg-4 mx-2'>Privacy</span>
                                <span className='mx-lg-4 mx-2'>Policy and Cookie policy </span>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </div>
            <div className='footer-backgroundc'>
                <Container fluid>
                    <Row>
                        <Col lg='12' md='12' sm='12'>
                            <div className='footer-flex'>
                                <div className='bold'>
                                    <p>Copyright @ 2024 , SrivaruGreenEnergy Pvt Ltd. All rights reserved</p>
                                </div>
                                <div className='bold'>
                                    <p>
                                        Developed By{' '}
                                        <span className='barbikan-link'>
                                            <a href='https://barbikan.in/'>Barbikan Technologies.</a>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    )
}

export default Footer