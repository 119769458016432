import React from 'react'
import { Col, Container,Row } from 'react-bootstrap'

const Others = () => {
  return (
    <div className='others'>
      <Container fluid>
          <Row >
              <Col lg='12' md='12' xs='12' className='py-3'>
                  <div className='Spares-title bold spare md-font navy'> Other Service</div>
              </Col>
              <Col lg="6" md='6' xs='12' className='py-3'>
                <div className='other-service-box'>
                    <div className='other-service-img'>
                      <img src={require('../../images/inspectone.webp')} className='img-fluid w-100' alt='' title=''/>
                    </div>
                   
                    <div className='inspct-description'>
                    <div className='inspect-title text-center'> Preventive maintenance</div>
                    Our commitment extends beyond mere service; it's a partnership dedicated to your success. With a team driven by excellence, we provide comprehensive solutions tailored to your needs. From installation to ongoing maintenance, trust us to deliver unparalleled service, ensuring your windmill operations run seamlessly, efficiently, and sustainably
                    </div>
                </div>
              </Col>
              <Col lg="6" md='6' xs='12' className='py-3'>
                <div className='other-service-box'>
                    <div className='other-service-img'>
                      <img src={require('../../images/local.png')} className='img-fluid w-100' alt='' title=''/>
                    </div>
                
                    <div className='inspct-description'>
                    <div className='inspect-title text-center'> EB Liaison work</div>
                      Our service goes beyond windmill maintenance; we act as a liaison with local electricity boards (EB), ensuring seamless integration and compliance. With our expertise in navigating regulatory requirements and facilitating communication, we streamline the process, guaranteeing efficient grid connections and uninterrupted energy supply.
                    </div>
                </div>
              </Col>
              <Col lg="6" md='6' xs='12' className='py-3'>
                <div className='other-service-box'>
                    <div className='other-service-img'>
                      <img src={require('../../images/onlinestatistics.webp')} className='img-fluid w-100' alt='' title=''/>
                    </div>
                    
                    <div className='inspct-description'>
                    <div className='inspect-title text-center'>  Online generation reports</div>
                        Our online generation reports service offers real-time insights into your windmill performance, empowering informed decision-making and maximizing efficiency. With user-friendly dashboards and customizable analytics, you gain a comprehensive overview of energy production, downtime analysis, and performance trends. Trust us to provide transparent, actionable data, ensuring your windmill operations run optimally and yield the highest possible returns
                    </div>
                </div>
              </Col>
              <Col lg="6" md='6' xs='12' className='py-3'>
                <div className='other-service-box'>
                    <div className='other-service-img'>
                      <img src={require('../../images/mechparts.webp')} className='img-fluid w-100' alt='' title=''/>
                    </div>
                    
                    <div className='inspct-description'>
                    <div className='inspect-title text-center'> Special Tools & Equipment</div>
                        Our specialized tools and equipment service is tailored to enhance the efficiency and precision of windmill maintenance. From advanced drone technology for aerial inspections to specialized diagnostic instruments, we ensure thorough assessments and swift repairs. With a focus on innovation and reliability, our tools and equipment enable us to deliver superior service, keeping your windmills operating at peak performance with minimal disruption
                    </div>
                </div>
              </Col>
          </Row>
      </Container>
    </div>
  )
}

export default Others