import React from 'react'
import '../impact/impact.css'
import { Container,Row,Col } from 'react-bootstrap'
import { MdEnergySavingsLeaf } from "react-icons/md";
const impact = () => {
  return (
    <div className='impact-section'>
        <Container className='padding'>
            <Row>
                <Col lg="6"md="12" sm="12" className='py-3'>
                    <div className="title md-font navy bold">our impact</div> 
                    <div className='greenborder'></div>
                    <div className='regular  mediumfont'> 
                      <div>We are Proud  of  impact we  have  made  so far</div>
                    </div>
                </Col>
                <Col lg='6'></Col>
                <Col lg='6' md='6' xs="12">
                  <div className='impact-collab'>
                    <div>
                      <img src={require('../../images/turbineve.webp')} alt='wind and solar power' title='wind and solar power'  className='img-fluid'/>
                      <div className='regular impact-description '>Our renewable energy projects have significantly reduced carbon emissions and contributed to the fight against climate change1. We are also committed to social responsibility and strive to make a positive impact on the communities where we operate.</div>
                      </div>
                  </div>
                </Col> 
                <Col lg='3' md="3"xs='12' className='align-self-end'>
                  <div className='text-center'>
                    <div className='box collab-box'>
                      <div className='icon-box thunder-bg text-white'><MdEnergySavingsLeaf /></div>
                        <div className='regular fw-600 box-title'>
                          <div>Huge Power </div>
                        </div>
                      </div>
                  </div>
                </Col>
                <Col lg='3'xs='12'>
                  <div className='text-lg-end'>
                      <img src={require('../../images/impact-collab.webp')} alt='wind and solar power' title='wind and solar power'  className='img-fluid  mx-auto '/>
                  </div>
                </Col>  
                  <Col lg='12'>
                    <div className='impact-banner'>
                     <img src={require('../../images/Banner.webp')} alt='wind and solar power' title='wind and solar power'  className='img-fluid'/>
                     </div>
                  </Col> 
            </Row>
        </Container>
        <Container className='padding'>
          <Row>
            <Col lg= '7' md='8' className='py-3'>
              <Row>
                <Col lg='7' md='6'> 
                  <div className='vision'>
                    <img src={require('../../images/verticalbanner.webp')} alt='wind and solar power' title='wind and solar power'  className='img-fluid'/>
                  </div>
                </Col>
                <Col lg='5' md='6'>
                  <Row>
                    <Col lg='12' md='6'>
                      <div className='my-2 vision'>
                        <img src={require('../../images/cloudturbine.webp')} alt='wind and solar power' title='wind and solar power'  className='img-fluid'/>
                      </div>
                    </Col>
                    <Col lg='12' md='6'>
                      <div className='my-lg-5 my-2 vision'>
                        <img src={require('../../images/wirepanel.webp')} alt='wind and solar power' title='wind and solar power'  className='img-fluid'/>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg='5' md='12' xs="12" className='align-self-center'>
                <div>
                  <div className='navy bold mediumfont'> Our Vision</div> 
                  <div className='regular fs-3'>Our vision is to be a global leader in renewable energy. We aim to continue expanding our portfolio of wind and solar energy projects and to play a key role in the global transition to renewable energy</div>
                </div>
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default impact