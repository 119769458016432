import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button,Modal  } from "react-bootstrap";
import "./gallery.css";
import axios from "axios";

const Gallery = () => {
    const BASE_URL = "https://api.srivarugreenenergy.com/uploads/";
    const [isLoading, setIsLoading] = useState(true);
    const [imageList, setImageList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(""); 
    const [startIndex, setStartIndex] = useState(0); // To track the start index of displayed images
    const imagesPerPage = 9; // Number of images to load per page

    const fetchImages = async () => {
        try {
            const response = await axios.get(
                "https://api.srivarugreenenergy.com/imageweb/list.php"
            );
            setImageList(response.data.images || []);
        } catch (error) {
            console.error("Failed to fetch images. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    // Function to load more images
    const handleLoadMore = () => {
        setStartIndex((prevStartIndex) => prevStartIndex + imagesPerPage);
    };

    // Function to load previous set of images
    const handleLoadPrevious = () => {
        setStartIndex((prevStartIndex) => Math.max(prevStartIndex - imagesPerPage, 0));
    };
    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl); // Set the clicked image
        setShowModal(true); // Show the modal
    };

    const handleCloseModal = () => {
        setShowModal(false); // Hide the modal
        setSelectedImage(""); // Clear the selected image
    };

    return (
        <div style={{marginTop:'120px'}}>
            <div className='navy  fw-bold bold text-center' style={{fontSize:'50px'}}>Our Photos</div>
            <Container>
                <Row className="mt-4">
                    {isLoading ? (
                        <p>Loading images...</p>
                    ) : imageList.length > 0 ? (
                        imageList
                            .slice(startIndex, startIndex + imagesPerPage) // Show images based on startIndex
                            .map((image, index) => (
                                <Col
                                    key={image.id || index}
                                    lg={4}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    className="mb-4"
                                >
                                    <div className="image-container" onClick={() => handleImageClick(`${BASE_URL}${image.image_url}`)}>
                                        
                                        <img
                                            src={`${BASE_URL}${image.image_url}`}
                                            alt={`Gallery ${index}`}
                                            className="img-fluid"
                                        />
                                    </div>
                                </Col>
                            ))
                    ) : (
                        <p>No images available.</p>
                    )}
                </Row>

                {/* Buttons for Load More and Previous */}
                <div className="text-center mt-4">
                    {startIndex > 0 && (
                        <Button variant="secondary" onClick={handleLoadPrevious} className="me-2">
                            Previous
                        </Button>
                    )}

                    {startIndex + imagesPerPage < imageList.length && (
                        <Button variant="primary" onClick={handleLoadMore}>
                            Load More
                        </Button>
                    )}
                </div>
            </Container>
            <Modal show={showModal} onHide={handleCloseModal} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img
                        src={selectedImage}
                        alt="Selected"
                        className="img-fluid"
                        style={{ maxHeight: "500px" }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Gallery;
